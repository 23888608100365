import React from "react";
import { Colors, Flex } from "@sellout/ui";
import * as Price from "@sellout/utils/.dist/price";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import PaymentUtil from "@sellout/models/.dist/utils/PaymentUtil";
import EventUtil from "@sellout/models/.dist/utils/EventUtil";
import styled from "styled-components";

const Row = styled.div`
  margin-bottom: 10px;
`;

const TitleBold = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${Colors.Grey1};
  margin-left: 7px;
`;

const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey1};
  margin-left: 7px;
`;

const SubTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${Colors.Grey2};
  margin-left: 20px;
  margin-top: 5px;
`;

const PriceText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey1};
`;

const PriceTextBold = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${Colors.Grey1};
`;

const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.Grey6};
  margin: 11px 0;
`;
enum FeeName {
  FeeName = "Sales tax",
}
type PaymentOverviewProps = { order?: any;  };

const OrderPaymentDetailsFees: React.FC<PaymentOverviewProps> = ({
  order,
}) => {
  const fees = order?.fees ?? [];
  let ticketPerMode = order?.tickets;
  const ticketTotal = ticketPerMode?.reduce(
    (cur, ticket) => cur + parseFloat(ticket.values as string),
    0
  );
  const { discountCode, payments, discountAmount } = order ?? [];
  let paymentsMethod = payments?.map((item) => item.paymentMethodType);
  let promotion = order?.event?.promotions.filter(
    (a) => a.code === discountCode
  );
  const updatedTicket = order?.tickets?.map((ticket) => ({
    ...ticket,
    price: ticket?.origionalPrice,
  }));


  const totalParams: any = {
    tickets: updatedTicket,
    upgrades: order?.upgrades ,
    fees: order?.fees,
    paymentMethodType: paymentsMethod[0],
    promotions: promotion,
  };

  const promoterFeeList = fees
    .filter(
      (f) =>
        f.name !== "Sales tax" && f.amount > 0 && f.appliedBy === "Organization"
    )
    .map((f) => ({
      name: f.name,
      amount: `$${Price.output(f.amount as number, true, true)}`,
    }));
  const subTotal = EventUtil.isRSVP(order as IEventGraphQL)
    ? 0
    : PaymentUtil.calculatePaymentTotal(totalParams).subTotal;
  const salesTaxFees = fees?.find((f) => f.name == "Sales tax");
  const salesTax: any = salesTaxFees
    ? (PaymentUtil.calculatePaymentTotal(totalParams).orderSubtotal *
        salesTaxFees.value) /
      100
    : 0;
  const selloutFees =
    PaymentUtil.calculatePaymentTotal(totalParams).selloutFees;
  const processingFees =
    PaymentUtil.calculatePaymentTotal(totalParams).stripeFees;
  const total = EventUtil.isRSVP(order as IEventGraphQL)
    ? ticketTotal
    : PaymentUtil.calculatePaymentTotal(totalParams).total;
  return (
    <>
      {discountCode && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Discount</Title>
            </Flex>
            <PriceText>${Price.output(discountAmount, true)}</PriceText>
          </Flex>
        </Row>
      )}
      <Row>
        <Flex justify="space-between">
          <Flex direction="row">
            <Title>Subtotal</Title>
          </Flex>
          <PriceText>${Price.output(subTotal, true)}</PriceText>
        </Flex>
      </Row>
      {salesTax > 0 && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Sales Tax</Title>
            </Flex>
            <PriceText>${Price.output(salesTax, true)}</PriceText>
          </Flex>
        </Row>
      )}
      {promoterFeeList?.map((item) => (
        <Row>
          <Flex justify="space-between">
            <>
              <Flex direction="row">
                <Title>{item.name}</Title>
              </Flex>
              <PriceText>{item.amount}</PriceText>
            </>
          </Flex>
        </Row>
      ))}
      {selloutFees > 0 && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Service Fee</Title>
            </Flex>
            <PriceText>${Price.output(selloutFees, true)}</PriceText>
          </Flex>
        </Row>
      )}
      {processingFees > 0 && (
        <Row>
          <Flex justify="space-between">
            <Flex direction="row">
              <Title>Processing Fees</Title>
            </Flex>
            <PriceText>${Price.output(processingFees, true)}</PriceText>
          </Flex>
        </Row>
      )}
      <Row>
        <Flex justify="space-between">
          <Flex direction="row">
            <TitleBold>Total</TitleBold>
          </Flex>
          <PriceTextBold>${Price.output(total, true)}</PriceTextBold>
        </Flex>
      </Row>
    </>
  );
};

export default OrderPaymentDetailsFees;
